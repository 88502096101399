<template>
  <div class="home-bg1">
    <div class="contact-bg">
      <img src="../../img/contactBg.jpg" alt="">
    </div>
    <div class="content-a wr">
      <div class="w1520">
        <div class="tit-50 hnb lh1">联系方式</div>
        <div class="list wr f-cb">
          <div class="left">
            <div class="tit-20">
              <p><span>公司地址：</span>西安市高新区高新三路8号橙市区空间5楼0520室</p>
              <p><span>电话：</span>400-029-2281（总机转）</p>
              <p><span>E-mail：</span>info@dbit365.com</p>
              <p><span>官网：</span>www.dbit365.com</p>
              <p><span>邮编：</span>710075</p>
            </div>
          </div>
          <div class="right">
            <div class="li">
              <img src="../../img/erweima.png" alt="">
              <div class="tit-16"></div>
            </div>                 
          </div>
        </div>
      </div>
    </div>
        <!-- <div class="cycle-wrapper">
            <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>联系我们</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="content">
                <div class="con-title">联系方式</div>
                <div class="con-text">
                    <i class="iconfont icon-wangluo"></i>
                    <span>官网：www.dbit365.com</span>
                </div>
                <div class="con-text">
                    <i class="iconfont icon-youxiang"></i>
                    <span>邮箱：info@dbit365.com</span>
                </div>
                <div class="con-text">
                    <i class="iconfont icon-24gf-telephone"></i>
                    <span>电话：400-029-2281</span>
                </div>
            </div>
        </div> -->
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
    user-select: none;
    width: 100%;
    position: relative;
    overflow: auto;
    .contact-bg {  
      width: 100%;
      // height: 68vh;
    }
    .content-a {
        padding: 6rem 0 2rem;
        background-color: #f6f6f6;
        .list {
            .left {
                width: 64%;
                float: left;
                padding-top: 2.1rem;
                border-right: 1px solid rgba(204, 204, 204, 0.5);
                .tit-20 {
                    text-align: left;
                    font-size: 0;
                    p {
                        // display: inline-block;
                        font-size: 1.25rem;
                        width: 60%;
                        margin-bottom: 1.88rem;
                        line-height: 1;
                    }
                }
            }
            .right {
                width: 20%;
                float: right;
                display: flex;
                justify-content: center;
                .li {
                    text-align: center;
                    margin: 0 1.56rem;
                }
            }
        }
    }
    .cycle-wrapper {
        padding: 90px 30px 0;
        color: #FFFFFF;
        .content {
            padding: 5px;
            margin: 0 35px;
            .con-title {
                font-size: 16px;
                width: 100px;
                height: 30px;
                background: #ffffff;
                border-radius: 15px;
                color: rgb(103, 103, 103);
                text-align: center;
                line-height: 30px;
                margin: 10px 0 20px;
            }
            .con-text {
                font-size: 16px;
                line-height: 35px;
                letter-spacing: 2px;
                display: flex;
                align-items: center;
                i {
                    margin-right: 10px;
                }
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>